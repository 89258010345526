import { Decipher } from "/src/js/classes/Decipher"
/*  */
new Decipher({
    target: document.querySelector("h2 span"),
    words: [
        "J0T0S0 Dev",
        "Giovanni Tosato",
        /* "UI/UX Designer",
        "Game Developer",
        "NFT Creator",
        "Team Leader", */
    ],
    detectorType: "auto",
    autoCooldown: 3000,
    cooldown: 40,
    attempts: 3,
})
