// Insert here the content types
const contentTypes = ["Websites", "Libraries", "Games", "WebApps"]
// select all the cards of the ecosystem
const cards = document.querySelectorAll(".folder.one .carousel .card")

let i = 0 // variable used as a special counter
let end = contentTypes.length // this is the end number
// For every card, add content in sequence
for (const card of cards) {
    card.innerHTML = `<p>${contentTypes[i]}</p>` // add current content (it s the variable i position of contentTypes)
    i++ // add to counter
    if (i == end) i = 0 // if it reached the end, reset
}

// Add event listener to the explore button
document.querySelector(".folder.one .explore").addEventListener("click", () => {
    document.querySelector(".folder.one").classList.toggle("exploring")
})
